<template>
  <div class="page-wrap">
    <img
      class="page-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/top.png"
    />
    <input
      class="input"
      v-model="phone_number"
      type="tel"
      placeholder="请输入手机号码"
      maxlength="11"
    />
    <img
      class="order-button"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/submit.png"
      @click="onSubmitOrder"
    />
    <div class="protocol-wrap" @click="checked = !checked">
      <img
        class="protocol-check"
        :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/${
          checked ? 'checked' : 'unchecked'
        }.png`"
      />
      <span>我已阅读并同意</span>
      <span class="protocol-text" @click.stop="visibility = true"
        >《服务协议》</span
      >
    </div>
    <img
      class="equity-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/equity1.png"
    />
    <img
      class="equity-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/equity3.png"
    />
    <div class="instructions_wrap">{{ instructionsText }}</div>
    <img
      class="page-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/fz_taiyue/rule.png"
    />
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <img
          class="dialog-bg"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/WuHanHeXin/dialog_bg.png"
        />
        <div class="dialog-text">{{ indexProtocolText }}</div>
        <img
          class="confirm-btn"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/WuHanHeXin/confirm.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { alipayPayAPI } from "@/api/taiyue/index";
import { Toast, Dialog } from "vant";
import { instructionsText, indexProtocolText } from "./data.js";
export default {
  data() {
    return {
      phone_number: "",
      checked: false,
      visibility: false,
      instructionsText,
      indexProtocolText,
    };
  },
  methods: {
    onSubmitOrder() {
      // Dialog.confirm({
      //   title: "提示",
      //   message: "是否确认订购该会员产品？",
      // });
      // return;
      if (!this.phone_number) return Toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      const pass = filterPhone.test(this.phone_number);
      if (!pass) return Toast("手机号码格式不正确");
      if (!this.checked) return Toast("请阅读并同意服务协议");
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      alipayPayAPI({
        item_name: "视听甄选会员",
        phone_number: this.phone_number,
      }).then((res) => {
        Toast.clear();
        if (res.code != 0) {
          Toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.page-wrap {
  position: relative;
  background-color: #fdebca;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-img {
    width: 100vw;
  }
  .equity-img {
    width: 90vw;
    margin: 20px 0;
  }
  .instructions_wrap {
    white-space: pre-wrap;
    font-size: 25px;
    line-height: 1.8;
    width: 90vw;
    margin: 20px 0;
  }
  .input {
    width: 85vw;
    height: 96px;
    border: none;
    border-radius: 50px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 730px;
    transform: translateX(-50%);
    padding: 0 32px;
    border: 2px solid #ffcba6;
  }
  .order-button {
    width: 85vw;
    height: 96px;
    border: none;
    position: absolute;
    left: 50%;
    top: 860px;
    transform: translateX(-50%);
  }
  .protocol-wrap {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 984px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #6e482c;
    .protocol-check {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .protocol-text {
      color: #ff7322;
    }
  }
  .dialog-wrap {
    background: none;
    overflow: initial;
    .dialog-content {
      position: relative;
      .dialog-bg {
        width: 90vw;
      }
      .dialog-text {
        height: 900px;
        overflow: auto;
        white-space: pre-wrap;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        width: 80vw;
        font-size: 28px;
      }
      .dialog-close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        z-index: 100;
      }
      .confirm-btn {
        width: 80%;
        position: absolute;
        bottom: 36px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
